import React from 'react';

const Chat = React.lazy(() => import('../module/chat'));
const Help = React.lazy(() => import('../module/help'));
const Profile = React.lazy(() => import('../module/profile'));
const Setting = React.lazy(() => import('../module/setting'));
const ProjectList = React.lazy(() => import('../module/project-list'));
const ProjectEdit = React.lazy(() => import('../module/project-edit'));
const ProjectDetail = React.lazy(() => import('../module/project-detail'));
const StudyList = React.lazy(() => import('../module/study-list'));
const StudyDetail = React.lazy(() => import('../module/study-detail'));
const StudyParticipant = React.lazy(() => import('../module/study-participant'));
const StudyDemographic = React.lazy(() => import('../module/study-demographic'));
const StudyEdit = React.lazy(() => import('../module/study-edit'));
const OrderList = React.lazy(() => import('../module/order-list'));
const OrderCheck = React.lazy(() => import('../module/order-check'));
const OrderPayment = React.lazy(() => import('../module/order-payment'));
const OrderResult = React.lazy(() => import('../module/order-result'));
const OrderBill = React.lazy(() => import('../module/order-bill'));
const OrderDetail = React.lazy(() => import('../module/order-detail'));
const UserList = React.lazy(() => import('../module/user-list'));
const UserCreate = React.lazy(() => import('../module/user-create'));

export const routeList = [
  { key: 'home', path: '/', element: <ProjectList /> },
  { key: 'chat', path: '/mesajlar', element: <Chat /> },
  { key: 'chat', path: '/mesajlar/:uuidTo', element: <Chat /> },
  { key: 'help', path: '/sikca-sorulan-sorular', element: <Help /> },
  { key: 'profile', path: '/profil', element: <Profile /> },
  { key: 'profile-section', path: '/profil/:section', element: <Profile /> },
  { key: 'setting', path: '/ayarlar', element: <Setting /> },
  { key: 'setting-section', path: '/ayarlar/:section', element: <Setting /> },
  {
    key: 'project-list',
    path: '/projeler',
    element: <ProjectList />,
  },
  {
    key: 'project-edit',
    path: '/proje/:projectId/duzenle',
    element: <ProjectEdit />,
  },
  {
    key: 'project-detail',
    path: '/proje/:projectId/detay',
    element: <ProjectDetail />,
  },
  {
    key: 'project-study',
    path: '/proje/:projectId/arastirma',
    element: <StudyList />,
  },
  {
    key: 'study-detail',
    path: '/proje/:projectId/arastirma/:studyId/detay',
    element: <StudyDetail />,
  },
  {
    key: 'study-participant',
    path: '/proje/:projectId/arastirma/:studyId/katilimcilar',
    element: <StudyParticipant />,
  },
  {
    key: 'study-demographic',
    path: '/proje/:projectId/arastirma/:studyId/demografik',
    element: <StudyDemographic />,
  },
  {
    key: 'study-edit',
    path: '/proje/:projectId/arastirma/:studyId/duzenle',
    element: <StudyEdit />,
  },
  {
    key: 'order-bill',
    path: '/siparis/:orderId/fatura',
    element: <OrderBill />,
  },
  {
    key: 'order-detail',
    path: '/siparis/:orderId/detay',
    element: <OrderDetail />,
  },
  {
    key: 'order-list',
    path: '/siparis',
    element: <OrderList />,
  },
  {
    key: 'order-check',
    path: '/siparis/:orderId',
    element: <OrderCheck />,
  },
  {
    key: 'order-payment',
    path: '/siparis/:orderId/odeme',
    element: <OrderPayment />,
  },
  {
    key: 'order-result',
    path: '/siparis/:orderId/sonuc',
    element: <OrderResult />,
  },
  // {
  //   key: 'bill-list',
  //   path: '/faturalar',
  //   element: <BillList />,
  // },
  { key: 'user-list', path: '/kullanicilar', element: <UserList /> },
  { key: 'user-create', path: '/kullanici-olustur', element: <UserCreate /> },
];

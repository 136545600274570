import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaWhatsapp } from 'react-icons/fa';
import { Badge, Tooltip } from 'antd';
import {
  FolderOutlined,
  SettingOutlined,
  UserOutlined,
  PayCircleOutlined,
  MessageOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { ProLayout } from '@ant-design/pro-components';
import Error404 from './module/Error404';
import Keycloak from './service/Keycloak';
import {
  selectChatNew,
  selectProfile,
  selectProjectList,
} from './service/store/selectors';
import { routeList } from './service/Route';
import Help from "./component/Help";

const appList = [
  {
    icon: '/android-chrome-192x192.png',
    title: 'YouReply',
    desc: 'Giriş sayfası',
    url: 'https://www.youreply.com.tr',
    target: '_blank',
  },
  {
    icon: '/img/icon/help.png',
    title: 'YouReply',
    desc: 'Yardım Merkezi',
    url: 'https://www.youreply.com.tr/yardim/',
    target: '_blank',
  },
];

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const chatNew = useSelector(selectChatNew);

  const [routeMenu, setRouteMenu] = useState({});

  const projectList = useSelector(selectProjectList);

  useEffect(() => {
    const routeMenuUpdated = {
      path: '/',
      routes: [
        {
          path: '/p',
          name: 'Projeler',
          routes: [
            {
              path: '/projeler',
              name: 'Projeler',
              icon: <FolderOutlined />,
            },
            // {
            //   path: '/proje-olustur',
            //   name: 'Yeni Proje Oluştur',
            //   icon: <FolderAddOutlined />,
            // },
          ],
        },
        {
          path: '/mesajlar',
          icon: <MessageOutlined />,
          name: (
            <div>
              <span>Mesajlar</span>
              <span> </span>
              <Badge count={chatNew.total} overflowCount={99} />
            </div>
          ),
        },
        {
          path: '/f',
          name: 'Finans',
          routes: [
            {
              path: '/siparis',
              name: 'Ödemeler',
              icon: <PayCircleOutlined />,
            },
            // {
            //   path: '/faturalar',
            //   name: 'Faturalar',
            //   icon: <ContainerOutlined />,
            // },
          ],
        },
        // {
        //   path: '/kullanicilar',
        //   name: 'Kullanıcılar',
        //   icon: <TeamOutlined />,
        // },
        {
          path: '/profil',
          name: 'Profil',
          icon: <InfoCircleOutlined />,
        },
        {
          path: '/ayarlar',
          name: 'Ayarlar',
          icon: <SettingOutlined />,
        },
        {
          path: 'https://api.whatsapp.com/send?phone=905322266894',
          name: 'WhatsApp Yardım ',
          icon: (
            <FaWhatsapp
              style={{
                color: '#25d366',
                fontSize: '18px',
              }}
            />
          ),
        },
      ],
    };

    projectList
      .filter((project) => project.isActive)
      .forEach((project) => {
        if (window.innerWidth < 993) {
          // @ts-ignore
          routeMenuUpdated.routes[0].routes?.push({
            path: `/proje/${project.projectId}/arastirma`,
            name: project.title,
          });
        } else {
          // @ts-ignore
          routeMenuUpdated.routes[0].routes?.push({
            path: `/proje/${project.projectId}/arastirma`,
            name: project.title,
            icon: <></>,
          });
        }
      });

    setRouteMenu(routeMenuUpdated);
  }, [projectList, chatNew]);

  return (
    <ProLayout
      fixSiderbar={true}
      fixedHeader={true}
      layout="side"
      siderWidth={275}
      logo="/img/logo.png"
      title="YouReply"
      route={routeMenu}
      appList={appList}
      location={{
        pathname: location.pathname,
      }}
      onMenuHeaderClick={() => {
        navigate('/');
      }}
      menu={{
        type: 'group',
        defaultOpenAll: true,
        autoClose: false,
      }}
      menuItemRender={(item, dom) => {
        return <Link to={item.path || '/'}>{dom}</Link>;
      }}
      avatarProps={{
        icon: <UserOutlined />,
        title: `${profile.nameFirst} ${profile.nameLast}`,
        size: 'small',
        shape: 'square',
        onClick: () => {
          navigate('/profil');
          // window.location.href = Keycloak.createAccountUrl();
        },
      }}
      actionsRender={(props) => {
        // eslint-disable-next-line react/prop-types
        if (props.isMobile) {
          return [];
        }

        return [
          <Tooltip key="InfoCircleFilled" title="Sıkça Sorulan Sorular">
            <Link to="/sikca-sorulan-sorular" style={{ color: 'inherit' }}>
              <InfoCircleOutlined />
            </Link>
          </Tooltip>,
          <Tooltip key="LogoutOutlined" title="Güvenli Çıkış Yap">
            <LogoutOutlined onClick={() => Keycloak.logout()} />
          </Tooltip>,
        ];
      }}
      menuRender={(props, defaultDom) => <>{defaultDom}</>}
      bgLayoutImgList={[
        {
          src: '/img/bg-sider.png',
          bottom: 0,
          left: 0,
          width: '331px',
        },
      ]}
    >
      <Routes>
        {routeList.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={
              <React.Suspense fallback={<></>}>{route.element}</React.Suspense>
            }
          />
        ))}
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Help />
    </ProLayout>
  );
}

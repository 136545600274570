import { IAction } from '../../Type';
import { LOAD_INITIAL, LOAD_INITIAL_CHAT } from "../actionTypes";

type IChatReducer = {
  total: number;
};

const initialState: IChatReducer = {
  total: 0,
};

export default function reducer(
  state = initialState,
  { type, payload }: IAction,
) {
  switch (type) {
    case LOAD_INITIAL:
    case LOAD_INITIAL_CHAT:
      return {
        ...state,
        total: payload.chatNew.total,
      };
    default:
      return state;
  }
}

import { Button, FloatButton } from 'antd';
import { FaMinus, FaWhatsapp } from 'react-icons/fa';
import React, { useState } from 'react';
import './Help.scss';

export default function Help() {
  const [open, setOpen] = useState(true);

  return (
    <>
      {open && (
        <div className="help-button">
          <div className="help-button-close">
            <Button
              icon={<FaMinus style={{ marginTop: 4 }} />}
              onClick={() => setOpen(false)}
            />
          </div>
          <img src="/img/logo-large.png" alt="YouReply" />
          <p>
            <strong>YouReply ile sohbet et</strong>
          </p>
          <p>Merhaba, size nasıl yardımcı olabiliriz?</p>
          <Button
            target="_blank"
            href="https://api.whatsapp.com/send?phone=905322266894"
            rel="noreferrer"
            type="primary"
            style={{
              width: '100%',
              background: '#25d366',
            }}
          >
            Sohbet Başlat
          </Button>
        </div>
      )}
      <FloatButton
        className="help-button-icon"
        icon={
          <FaWhatsapp
            style={{
              fontSize: '18px',
            }}
          />
        }
        type="default"
        style={{
          width: 48,
          height: 48,
          right: 40,
          bottom: 10,
        }}
        onClick={() => setOpen(!open)}
      />
    </>
  );
}

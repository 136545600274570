import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { store } from '../store/store';
import { loadInitial as loadInitialAction, loadInitialChatAction } from "../store/actions";

export const loadInitial = async () => {
  try {
    const result = await client.query({
      query: gql`
        query initial {
          profile {
            uuid
            nameLast
            nameFirst
            email
            phone
            isActive
            isValid
            isOnBoarded
            isOnBoardedModule
          }
          chatNew {
            total
          }
          account {
            isAnyUserCreated
            commission
          }
          workspace {
            isAnyProjectCreated
          }
          projectList(options: { filter: { isActive: "1" } }) {
            projectId
            title
            isActive
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    await store.dispatch(
      loadInitialAction({
        profile: result.data.profile,
        chatNew: result.data.chatNew,
        account: result.data.account,
        workspace: result.data.workspace,
        projectList: result.data.projectList,
      }),
    );

    return result.data.profile;
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }
};

export const loadInitialChat = async () => {
  try {
    const result = await client.query({
      query: gql`
        query initialChatNew {
          chatNew {
            total
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    await store.dispatch(
      loadInitialChatAction({
        chatNew: result.data.chatNew,
      }),
    );

    return result.data.profile;
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }
};

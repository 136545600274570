import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Formik, ErrorMessage } from 'formik';
import { Input, Form, Button, notification, Alert, Col, Row } from 'antd';
import * as yup from 'yup';
import { ProCard, FooterToolbar } from '@ant-design/pro-components';
import { SaveOutlined, MailOutlined } from '@ant-design/icons';
import { IError, IProfile, IProfilePatchData } from '../../../service/Type';
import { loadProfile, profileSave } from '../../../service/api/Profile';
import { loadInitial } from '../../../service/api/Initial';
import { isValidPhoneNumber } from '../../../service/validate/PhoneNumber';
import { FormContactSkeleton } from './FormContactSkeleton';

interface FormContactProps {
  onSubmitProProcess?: any;
}

export default function FormContact({ onSubmitProProcess }: FormContactProps) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [profile, setProfile] = useState<IProfile>();

  const load = async () => {
    const profileResult = await loadProfile();
    if (profileResult.result) {
      await setProfile(profileResult.result);
    }

    if (profileResult.errors) {
      await setError(profileResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (!profile || loading) {
    return <FormContactSkeleton />;
  }

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        description="Ayarlar yüklenirken hata oldu."
      />
    );
  }

  const initialValues: IProfilePatchData = {
    email: profile.email,
    phone: profile.phone,
  };

  const onSubmit = async (values: IProfilePatchData) => {
    const profile = await profileSave(values);
    if (profile.result) {
      navigate(`/`);
      notification.success({
        message: 'Başarı',
        description: 'İletişim bilgileri başarıyla kaydedildi! ',
      });
      await loadInitial();
      if (onSubmitProProcess) {
        onSubmitProProcess();
      }
    }
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .typeError('Email girilmelidir')
      .required('Email girilmelidir')
      .email('Geçerli bir eposta adresi girilmelidir. '),
    phone: yup
      .string()
      .typeError('Telefon numarası girilmelidir')
      .required('Telefon numarası girilmelidir')
      // @ts-ignore
      .test('phone', 'Geçerli bir telefon numarası girilmelidir', (value) => {
        if (!value) {
          return false;
        }

        return isValidPhoneNumber(value);
      })
      .min(13, 'Telefon numarası en az 13 karakter uzunluğunda olabilir')
      .max(13, 'Telefon numarası en fazla 13 karakter uzunluğunda olabilir'),
  });

  return (
    <>
      <Alert
        showIcon
        description="İletişim bilgileriniz üçüncü şahıslar ile kesinlikle paylaşılmayacaktır. Yalnızca yardımımıza ihtiyaç duyduğunuzda sizinle doğrudan iletişime geçebilmemiz için kullanılacaktır. "
        style={{ marginBottom: 16 }}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          await onSubmit(values);
          return true;
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          handleSubmit,
          handleBlur,
        }) => (
          <Form layout="vertical">
            <ProCard title="İletişim Bilgileri" style={{ marginBottom: 16 }}>
              <Row gutter={16}>
                <Col span={24} md={{ span: 12 }}>
                  <Form.Item label="Email">
                    <Input
                      name="email"
                      prefix={<MailOutlined />}
                      value={values.email ? values.email : ''}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      disabled
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="ant-form-feedback"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <Form.Item label="Telefon Numarası">
                    <NumberFormat
                      className="ant-input-number"
                      isNumericString
                      value={values.phone?.replace('+905', '')}
                      allowEmptyFormatting
                      format="+90 5## ### ####"
                      mask="_"
                      onValueChange={(changes: any) => {
                        setFieldValue('phone', `+905${changes.value}`);
                      }}
                      onBlur={() => {
                        handleBlur('phone');
                      }}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="ant-form-feedback"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ProCard>

            <FooterToolbar>
              <Button
                onClick={() => handleSubmit()}
                type="primary"
                disabled={isSubmitting}
                icon={<SaveOutlined />}
              >
                Kaydet
              </Button>
            </FooterToolbar>
          </Form>
        )}
      </Formik>
    </>
  );
}

import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Form, Button, Switch } from 'antd';
import * as yup from 'yup';
import { ProCard, FooterToolbar } from '@ant-design/pro-components';
import { CheckOutlined } from '@ant-design/icons';
import KvkkContract from '../../../component/content/KvkkContract';
import TermContract from '../../../component/content/TermContract';
import UserContact from '../../../component/content/UserContract';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../../service/store/selectors';

interface FormContractProps {
  onSubmitProProcess?: any;
}

export default function FormContract({
  onSubmitProProcess,
}: FormContractProps) {
  const profile = useSelector(selectProfile);

  const initialValues = {
    isKvkkContractAccepted: false,
    isTermContractAccepted: false,
    isUserContractAccepted: false,
  };

  const onSubmit = async (values: any) => {
    if (
      values.isKvkkContractAccepted &&
      values.isTermContractAccepted &&
      values.isUserContractAccepted &&
      onSubmitProProcess
    ) {
      onSubmitProProcess();
    }
  };

  const schema = yup.object().shape({
    isKvkkContractAccepted: yup
      .boolean()
      .required('Kvkk onay sözleşmesi okunmalı ve kabul edilmelidir')
      .oneOf([true], 'Kvkk onay sözleşmesi okunmalı ve kabul edilmelidir'),
    isTermContractAccepted: yup
      .boolean()
      .required('Kullanım sözleşmesi okunmalı ve kabul edilmelidir')
      .oneOf([true], 'Kullanım sözleşmesi okunmalı ve kabul edilmelidir'),
    isUserContractAccepted: yup
      .boolean()
      .required('Kullanıcı sözleşmesi okunmalı ve kabul edilmelidir')
      .oneOf([true], 'Kullanıcı sözleşmesi okunmalı ve kabul edilmelidir'),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          await onSubmit(values);
          return true;
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
          <Form layout="vertical">
            <ProCard title="KVKK Onay Sözleşmesi" style={{ marginBottom: 16 }}>
              <div
                style={{
                  overflow: '-moz-scrollbars-vertical',
                  overflowY: 'scroll',
                  height: 200,
                  marginBottom: 16,
                  borderBottom: '1px solid #f4f4f4',
                  borderTop: '1px solid #f4f4f4',
                }}
              >
                <KvkkContract />
              </div>
              <Switch
                checked={values.isKvkkContractAccepted as boolean}
                onChange={(checked) =>
                  setFieldValue('isKvkkContractAccepted', checked)
                }
                style={{ marginRight: 16 }}
              />
              Okudum anladım, verilerimin işlenmesine onay veriyorum.
              <ErrorMessage
                name="isKvkkContractAccepted"
                component="div"
                className="ant-form-feedback"
              />
            </ProCard>

            <ProCard
              title="Kullanım Koşulları Sözleşmesi"
              style={{ marginBottom: 16 }}
            >
              <div
                style={{
                  overflow: '-moz-scrollbars-vertical',
                  overflowY: 'scroll',
                  height: 200,
                  marginBottom: 16,
                  borderBottom: '1px solid #f4f4f4',
                  borderTop: '1px solid #f4f4f4',
                }}
              >
                <TermContract />
              </div>
              <Switch
                checked={values.isTermContractAccepted as boolean}
                onChange={(checked) =>
                  setFieldValue('isTermContractAccepted', checked)
                }
                style={{ marginRight: 16 }}
              />
              Okudum anladım, kabul ediyorum.
              <ErrorMessage
                name="isTermContractAccepted"
                component="div"
                className="ant-form-feedback"
              />
            </ProCard>

            <ProCard
              title="Kullanıcı Koşulları Sözleşmesi"
              style={{ marginBottom: 16 }}
            >
              <div
                style={{
                  overflow: '-moz-scrollbars-vertical',
                  overflowY: 'scroll',
                  height: 200,
                  marginBottom: 16,
                  borderBottom: '1px solid #f4f4f4',
                  borderTop: '1px solid #f4f4f4',
                }}
              >
                <UserContact
                  userName={`${profile.nameFirst} ${profile.nameLast}`}
                  userEmail={profile.email || ''}
                  userAddress={profile.address || ''}
                  userPhone={profile.phone || ''}
                  userIdentity={profile.identity || ''}
                />
              </div>
              <Switch
                checked={values.isUserContractAccepted as boolean}
                onChange={(checked) =>
                  setFieldValue('isUserContractAccepted', checked)
                }
                style={{ marginRight: 16 }}
              />
              Okudum anladım, kabul ediyorum.
              <ErrorMessage
                name="isUserContractAccepted"
                component="div"
                className="ant-form-feedback"
              />
            </ProCard>

            <FooterToolbar>
              <Button
                onClick={() => handleSubmit()}
                type="primary"
                disabled={isSubmitting}
                icon={<CheckOutlined />}
              >
                Kabul Ediyorum
              </Button>
            </FooterToolbar>
          </Form>
        )}
      </Formik>
    </>
  );
}

import { IError } from '../Type';
import { GraphQLError } from 'graphql';

export function handleError(
  errors: readonly GraphQLError[] | undefined,
): IError | undefined {
  console.log(errors);

  if (errors && errors?.length > 0) {
    return {
      code: 500,
      message: errors[0].message,
    };
  }

  // if (errors && errors?.length > 0) {
  //   return {
  //     code: 404,
  //     message: 'Not Found',
  //   };
  // }

  // if (errors) {
  //   for (const err of errors) {
  //     switch (err.extensions.code) {
  //       case 'UNAUTHENTICATED':
  //         return {
  //           code: 401,
  //           message: 'Unauthenticated',
  //         };
  //       case 'UNAUTHORIZED':
  //         return {
  //           code: 403,
  //           message: 'Unauthorized',
  //         };
  //       case 'NOTFOUND':
  //         return {
  //           code: 404,
  //           message: 'Not Found',
  //         };
  //       default:
  //     }
  //   }
  //
  //   return {
  //     code: 404,
  //     message: 'Not Found',
  //   };
  // }

  return undefined;
}

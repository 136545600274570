import { LOAD_INITIAL, LOAD_INITIAL_CHAT, ON_BOARD_MODULE } from "./actionTypes";
import { IAccount, IChatCount, IProfile, IProject, IWorkspace } from '../Type';

export const loadInitial = (payload: {
  profile: IProfile;
  chatNew: IChatCount;
  account: IAccount;
  workspace: IWorkspace;
  projectList: IProject[];
}) => ({
  type: LOAD_INITIAL,
  payload,
});

export const loadInitialChatAction = (payload: {
  chatNew: IChatCount;
}) => ({
  type: LOAD_INITIAL_CHAT,
  payload,
});

export const moduleOnBoard = (payload: { module: string }) => ({
  type: ON_BOARD_MODULE,
  payload,
});

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import LogRocket from 'logrocket';
import reportWebVitals from './reportWebVitals';
import { store } from './service/store/store';
import { loadInitial } from './service/api/Initial';
import keycloak from './service/Keycloak';
import App from './App';

import * as dayjs from 'dayjs';
import 'dayjs/locale/tr';

dayjs.locale('tr');

import AppOnBoard from './AppOnBoard';
import './index.scss';

import trTr from 'antd/locale/tr_TR';

trTr.Tour = {
  Next: 'Sonraki',
  Previous: 'Önceki',
  Finish: 'Tamamla',
};

async function init() {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      enableLogging: true,
      responseMode: 'query',
      checkLoginIframeInterval: 60,
    });

    if (!authenticated) {
      await keycloak.login();
    }

    setInterval(() => {
      keycloak
        .updateToken(180)
        .then(function (refreshed) {
          if (refreshed) {
            console.log('Token was successfully refreshed');
            localStorage.setItem('token', keycloak.token as string);
          } else {
            console.log('Token is still valid');
          }
        })
        .catch(function () {
          console.log(
            'Failed to refresh the token, or the session has expired',
          );
        });
    }, 60 * 1000);

    localStorage.setItem('token', keycloak.token as string);

    const profile = await loadInitial();
    const container = document.getElementById('youreply')!;
    const root = createRoot(container);

    if (process.env.NODE_ENV !== 'development' && profile && profile.email) {
      LogRocket.init('hvazje/youreply-researcher');
      LogRocket.identify(profile.email, {
        name: `${profile.nameFirst} ${profile.nameLast}`,
        email: profile.email,
      });
    }

    root.render(
      <>
        <BrowserRouter>
          <ConfigProvider
            locale={trTr}
            theme={{
              token: {
                colorPrimary: '#106fff',
                fontFamily: "'Inter', sans-serif;",
              },
            }}
          >
            <Provider store={store}>
              {profile.isOnBoarded && <App />}
              {!profile.isOnBoarded && <AppOnBoard />}
            </Provider>
          </ConfigProvider>
        </BrowserRouter>
      </>,
    );
  } finally {
    // Do nothing for now
  }
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

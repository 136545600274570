import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Result, StepProps, Steps, Tooltip } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { FooterToolbar, ProCard, ProLayout } from '@ant-design/pro-components';
import Keycloak from './service/Keycloak';
import { selectProfile } from './service/store/selectors';
import { profileOnBoardInitial } from './service/api/Profile';
import FormProfile from './module/profile/component/FormProfile';
import FormContact from './module/profile/component/FormContact';
import FormContract from "./module/profile/component/FormContract";

export default function AppOnBoard() {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const [current, setCurrent] = useState(0);

  const items: StepProps[] = [
    {
      title: 'Hoş geldiniz',
    },
    {
      title: 'Sözleşme Onayları',
    },
    {
      title: 'Kişisel Bilgiler',
    },
    {
      title: 'İletişim Bilgileri',
    },
    {
      title: 'Başlayın',
    },
  ];

  const onChange = (value: number) => {
    setCurrent(value);
  };

  const onSubmitNext = () => {
    onChange(current + 1);
  };

  const onFinalize = async () => {
    await profileOnBoardInitial();
    window.location.reload();
  };

  return (
    <ProLayout
      layout="top"
      logo="/img/logo.png"
      title="YouReply"
      onMenuHeaderClick={() => {
        navigate('/');
      }}
      avatarProps={{
        icon: <UserOutlined />,
        title: `${profile.nameFirst} ${profile.nameLast}`,
        size: 'small',
        shape: 'square',
        onClick: () => {
          // navigate('/profil');
          // window.location.href = Keycloak.createAccountUrl();
        },
      }}
      actionsRender={(props) => {
        // eslint-disable-next-line react/prop-types
        if (props.isMobile) {
          return [];
        }

        return [
          <Tooltip key="LogoutOutlined" title="Güvenli Çıkış Yap">
            <LogoutOutlined onClick={() => Keycloak.logout()} />
          </Tooltip>,
        ];
      }}
    >
      <div className="wrapper-onboarding">
        <Steps
          current={current}
          direction="horizontal"
          items={items}
          style={{
            marginBottom: 16,
            padding: 16,
            borderBottom: '1px solid #ccc',
          }}
        />
        {current === 0 && (
          <>
            <ProCard title="Değerli araştırmacımız, aramıza hoş geldiniz! 😊">
              <p>
                Platformumuz, anketlerinizi paylaşarak farklı insanların
                görüşlerini almanızı ve araştırmanızı daha geniş bir katılımcı
                havuzu ile paylaşmanızı sağlayan bir çevrimiçi kitle kaynak
                merkezidir. Size ait olan bu sayfada ankete katılacak kişiler
                için anketinizi tanımlayabilir, bağlantısını paylaşabilir ve
                hedef kitlenize kısa süre içerisinde ulaşabilirsiniz.
              </p>
              <p>
                Anketler, bilimsel ve toplumsal araştırmalar yapmak, mal ve
                hizmetleri iyileştirmek, siyasi ve sosyal sorunları anlamak için
                sıklıkla kullanılan bir araçtır. Anketinizi paylaşarak daha
                fazla insanın katılımını ve sonuçlarınızın daha güvenilir
                olmasını sağlayabilirsiniz. Araştırmalarınızda beklediğiniz
                sonuçları elde etmeniz dileğiyle.
              </p>
              <div style={{ textAlign: 'right' }}>
                <div>Teşekkür ederiz!</div>
                <div>YouReply Ekibi</div>
              </div>
            </ProCard>
            <FooterToolbar>
              <Button type="primary" onClick={onSubmitNext}>
                Devam Et
              </Button>
            </FooterToolbar>
          </>
        )}
        {current === 1 && <FormContract onSubmitProProcess={onSubmitNext} />}
        {current === 2 && <FormProfile onSubmitProProcess={onSubmitNext} />}
        {current === 3 && <FormContact onSubmitProProcess={onSubmitNext} />}
        {current === items.length - 1 && (
          <>
            <Result
              status="success"
              title="Bilgileriniz başarıyla alındı!"
              subTitle=""
              extra={<Button onClick={onFinalize}>Başlayın</Button>}
            />
          </>
        )}
      </div>
    </ProLayout>
  );
}

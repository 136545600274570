import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import keycloak from '../Keycloak';

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  // if (graphQLErrors)
  //   graphQLErrors.forEach(({ message, locations, path }) =>
  //     console.log(
  //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
  //     ),
  //   );
  //
  // if (networkError) console.log(`[Network error]: ${networkError}`);

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED':
          // Modify the operation context with a new token
          keycloak.login();

          // eslint-disable-next-line no-case-declarations
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: keycloak.token,
            },
          });

          // Retry the request, returning the new observable
          return forward(operation);
        default:
      }
    }
    // return forward(operation);
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL_API,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  const headersUpdated = {
    ...headers,
  };

  if (token) {
    headersUpdated.Authorization = token;
  }

  return {
    headers: headersUpdated,
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

export const client = new ApolloClient({
  link: from([retryLink, errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import {
  Input,
  Form,
  Button,
  notification,
  Alert,
  DatePicker,
  Col,
  Row,
} from 'antd';
import * as yup from 'yup';
import { ProCard, FooterToolbar } from '@ant-design/pro-components';
import { SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { IError, IProfile, IProfilePatchData } from '../../../service/Type';
import { loadProfile, profileSave } from '../../../service/api/Profile';
import { loadInitial } from '../../../service/api/Initial';
import { isValidTc } from '../../../service/validate/TcKimlik';
import { nviValidateTc } from '../../../service/api/Nvi';

interface FormProfileProps {
  onSubmitProProcess?: any;
}

export default function FormProfile({ onSubmitProProcess }: FormProfileProps) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [profile, setProfile] = useState<IProfile>();

  const load = async () => {
    const profileResult = await loadProfile();
    if (profileResult.result) {
      await setProfile(profileResult.result);
    }
    if (profileResult.errors) {
      await setError(profileResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (!profile || loading) {
    return <p />;
  }

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        description="Ayarlar yüklenirken hata oldu."
      />
    );
  }

  const initialValues: IProfilePatchData = {
    nameFirst: profile.nameFirst as string,
    nameLast: profile.nameLast as string,
    identity: profile.identity as string,
    birthdate: profile.birthdate,
  };

  const onSubmit = async (values: IProfilePatchData) => {
    const profile = await profileSave(values);
    if (profile.result) {
      navigate(`/`);
      notification.success({
        message: 'Başarı',
        description: 'Kişisel bilgileriniz başarıyla kaydedildi! ',
      });
      await loadInitial();

      // On Boarding
      if (onSubmitProProcess) {
        onSubmitProProcess();
      }
    }
    if (profile.errors) {
      notification.error({
        message: 'Hata',
        description:
          'Bu TC kimlik numarası ile kayıtlı başka biri sistemde kayıtlı. Eğer bir yanlışlık olduğunu düşünüyorsanız lütfen bizimle iletişime geçin.  ',
      });
    }
  };

  const shape = {
    nameFirst: yup
      .string()
      .typeError('Ad girilmelidir')
      .required('Ad girilmelidir')
      .max(32, 'Ad en fazla 32 karakter uzunluğunda olabilir'),
    nameLast: yup
      .string()
      .typeError('Soyad girilmelidir')
      .required('Soyad girilmelidir')
      .max(32, 'Soyad en fazla 32 karakter uzunluğunda olabilir'),
    birthdate: yup
      .date()
      .typeError('Doğum tarihi girilmelidir')
      .required('Doğum tarihi girilmelidir. '),
    identity: yup
      .string()
      .typeError('Tc kimlik numarası girilmelidir')
      .required('Tc kimlik numarası girilmelidir')
      .max(11, 'Tc kimlik numarası en fazla 11 karakter uzunluğunda olabilir')
      .test(
        'identity',
        'Geçerli bir tc kimlik numarası girilmelidir',
        (value) => {
          if (!value) {
            return false;
          }

          return isValidTc(value);
        },
      ),
  };

  if (!onSubmitProProcess) {
    shape.identity.test(
      'others',
      'Ad, soyad, doğum tarihi, tc kimlik numarası kontrolü hatalı. ',
      // @ts-ignore
      async (identity, context) => {
        if (!identity) {
          return false;
        }

        if (!context.parent.nameFirst) {
          return false;
        }

        if (!context.parent.nameLast) {
          return false;
        }

        if (!context.parent.birthdate) {
          return false;
        }

        const result = await nviValidateTc({
          identity: identity,
          nameFirst: context.parent.nameFirst,
          nameLast: context.parent.nameLast,
          birthYear: context.parent.birthdate.toISOString().substring(0, 4),
        });

        console.log('result.result', result.result);
        return result.result?.status;
      },
    );
  }

  const schema = yup.object().shape(shape);

  const isFormDisabled = !!profile.isValid && !onSubmitProProcess;

  return (
    <>
      <Alert
        showIcon
        description="Kişisel bilgileriniz üçüncü şahıslar ile kesinlikle paylaşılmayacaktır. Bu bilgiler yalnızca hesabınızı doğrulamak için gereklidir."
        style={{ marginBottom: 16 }}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          await onSubmit(values);
          return true;
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          handleSubmit,
          handleBlur,
        }) => (
          <Form layout="vertical">
            <ProCard title="Kişisel Bilgiler" style={{ marginBottom: 16 }}>
              <Row gutter={16}>
                <Col span={24} md={{ span: 12 }}>
                  <Form.Item label="Ad">
                    <Input
                      name="nameFirst"
                      value={values.nameFirst ? values.nameFirst : ''}
                      onChange={(e) =>
                        setFieldValue('nameFirst', e.target.value)
                      }
                      disabled={isFormDisabled}
                    />
                    <ErrorMessage
                      name="nameFirst"
                      component="div"
                      className="ant-form-feedback"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <Form.Item label="Soyad">
                    <Input
                      name="nameLast"
                      value={values.nameLast ? values.nameLast : ''}
                      onChange={(e) =>
                        setFieldValue('nameLast', e.target.value)
                      }
                      disabled={isFormDisabled}
                    />
                    <ErrorMessage
                      name="nameLast"
                      component="div"
                      className="ant-form-feedback"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} md={{ span: 12 }}>
                  <Form.Item label="Doğum Tarihi">
                    <DatePicker
                      showToday={false}
                      onChange={(date) => {
                        if (date) {
                          setFieldValue(
                            'birthdate',
                            date?.format('YYYY-MM-DD'),
                          );
                        } else {
                          setFieldValue('birthdate', undefined);
                        }
                      }}
                      value={
                        values.birthdate
                          ? dayjs(values.birthdate, 'YYYY-MM-DD')
                          : undefined
                      }
                      format="DD.MM.YYYY"
                      disabledDate={(current) => {
                        return (
                          current > dayjs().subtract(18, 'years').endOf('day')
                        );
                      }}
                      style={{ width: '100%' }}
                      placeholder="GG.AA.YYYY"
                      disabled={isFormDisabled}
                    />

                    <ErrorMessage
                      name="birthdate"
                      component="div"
                      className="ant-form-feedback"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <Form.Item label="Tc Kimlik Numarası">
                    <NumberFormat
                      className="ant-input-number"
                      isNumericString
                      value={values.identity ? values.identity : ''}
                      allowEmptyFormatting
                      format="###########"
                      mask="_"
                      onValueChange={(changes: any) => {
                        setFieldValue('identity', changes.value);
                      }}
                      onBlur={() => {
                        handleBlur('identity');
                      }}
                      disabled={isFormDisabled}
                    />
                    <ErrorMessage
                      name="identity"
                      component="div"
                      className="ant-form-feedback"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ProCard>

            {!isFormDisabled && (
              <FooterToolbar>
                <Button
                  onClick={() => handleSubmit()}
                  type="primary"
                  disabled={isSubmitting}
                  icon={<SaveOutlined />}
                >
                  Kaydet
                </Button>
              </FooterToolbar>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}

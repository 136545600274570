import React from 'react';

export default function KvkkContract() {
  return (
    <>
      <p>
        <strong>
          Kişisel verileriniz, 6698 sayılı Kişisel Verilerin Korunması Kanunu
          kapsamında&nbsp;Youreply Teknoloji Ticaret Eğitim Danışmanlık
          Araştırma Organizasyon Anonim Şirketi tarafından işlenebilecektir.
        </strong>
      </p>

      <p>
        Veri Sorumlusu olarak hazırladığımız işbu Aydınlatma Metni ile Kanun’da
        yer alan “Veri Sorumlusunun Aydınlatma Yükümlülüğü” kapsamında hangi
        amaçlar ile kişisel verilerinizin işleneceği, &nbsp;topladığımız
        bilgilerin nasıl kullanılacağı, işlenen kişisel verilerinizin kimlere ve
        hangi amaçla aktarılabileceği, kişisel verilerinizin toplanmasının
        yöntemi, hukuki sebebi ve Kanun’un 11. Maddesinde sayılan diğer
        haklarınızla ilgili olarak kullanıcılara bilgi vermek istiyor,
        hizmetlerimizde kişisel bilgilerinizi nasıl topladığımızı ve
        kullandığımızı anladığınızdan emin olmak istiyoruz.
      </p>

      <p>
        <strong>Kişisel Verilerin İşlenme Amacı</strong>
      </p>

      <p>
        Kişisel verileriniz, 6698 sayılı Kanun’un 5 ve 6 numaralı maddelerinde
        belirtilen kişisel verilerin işlenmesine ilişkin şartlara uygun olarak
        işlenmektedir.
      </p>

      <p>
        Kişisel verilerinizin sözlü, yazılı ya da elektronik ortamda toplanması
        ve işlenmesi amaçları bunlarla sınırlı olmamak üzere, C2C iş modelimiz
        kapsamında bu bilgileri size hizmet sunmak, hizmetlerimizi kullanma
        şeklinizi anlamak ve böylece deneyimlerinizi geliştirebilmek ve
        kişiselleştirebilmek, Youreply platformu üzerinden araştırma yapmak
        isteyen bireyleri ve kuruluşları hedef katılımcılarıyla buluşturarak
        doğru bilgi almalarını sağlayan kitle kaynak destekli güvenilir bir
        çevrim içi araştırma platformunu oluşturmak, pazar araştırması yapmak,
        ödeme işlemlerinin gerçekleştirilebilmesi amacıyla kullanıyoruz. Ayrıca
        ilgi alanlarınıza göre özelleştirilmiş reklamlar sunmak için de kişisel
        bilgileri kullanıyoruz.
      </p>

      <p>
        <strong>Kişisel Verilerin Paylaşılması</strong>
      </p>

      <p>
        Kişisel bilgilerinizi, Youreply Teknoloji Ticaret Eğitim Danışmanlık
        Araştırma Organizasyon Anonim Şirketi’nin hizmetinden yararlanan
        araştırma yapmak isteyen bireyler ve kuruluşlar ile paylaşabiliriz.
        Kişisel bilgileri kanunen gerektiğinde Youreply’ı ve diğer müşterileri
        koruma amacıyla kolluk kuvvetleriyle paylaşırız. Kişisel bilgilerinizi,
        araştırma işlemlerini gerçekleştirmek; yönetim raporlama ihtiyaçlarının
        karşılanması; çeşitli mevzuatlardan kaynaklanan hukuki zorunlulukları
        yerine getirmek; yasal prosedürler dahil olmak üzere şirketin yasal
        haklarının kullanılmasının sağlanması ve onayınızı almak kaydıyla başka
        amaçlar için paylaşabiliriz.
      </p>

      <p>
        <strong>Veri Sorumlusu Sıfatıyla İşlenen Veriler</strong>
      </p>

      <p>
        <strong>Youreply</strong>
        <strong>
          Teknoloji Ticaret Eğitim Danışmanlık Araştırma Organizasyon Anonim
          Şirketi
        </strong>
        olarak kişisel bilgilerinizi çeşitli yollarla toplamaktayız. Örneğin:
      </p>

      <ol type="1">
        <li>
          Bir hesap veya profil oluşturduğunuzda adınız, Türkiye Cumhuriyeti
          kimlik numaranız, doğum tarihiniz, telefon numaranız, fotoğrafınız
          veya e-posta adresiniz gibi hakkınızdaki bazı bilgileri isteyebiliriz.
        </li>

        <li>
          Anket/form/başvuru verileri (sorular ve yanıtlar) sizin için saklanır
          ve bu verilerde kullanmanız için tarafınıza analiz araçları sağlanır.
          Araştırma linkinde yer alan sorular kapsamında gerekli yaş, eğitim
          düzeyi, yaşam tarzı, tercihler, ırk, etnik köken, siyasi düşünce,
          felsefi inanç, din, mezhep veya diğer inançlar, kılık ve kıyafet,
          dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza
          mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler, biyometrik ve
          genetik veriler&nbsp;hakkındaki bazı özel nitelikli kişiler bilgileri
          isteyebiliriz.
        </li>

        <li>
          Ödeme işlemlerini gerçekleştirebilmek adına adınız, adresiniz,
          iletişim bilgileriniz, gönderim ve ödeme bilgileriniz, banka
          hesabınız, banka ve kredi kartı hakkında bilgilerinizi isteyebiliriz.
        </li>

        <li>
          Müşteri Hizmetleri ile iletişime geçtiğinizde yanıt sunmak için kendi
          isteğinizle sağladığınız veya sorununuzu çözüme kavuşturmak için
          ihtiyaç duyduğumuz bilgileri isteyebiliriz.
        </li>

        <li>
          Site kullanım bilginizi, cihaz ve tarayıcı verilerinizi hizmetlerimizi
          siz ve tüm kullanıcılar için geliştirmek için kullanabiliriz.
        </li>

        <li>
          Ek bilgi toplamamızın gerekli olduğu hallerde sizden izin
          isteriz.&nbsp;
        </li>
      </ol>

      <p>
        Bize göndereceğiniz tüm bilgileri ve içerikleri Youreply platformu
        üzerinden araştırma yapmak isteyen bireyler ve kuruluşların her zaman
        kaydedebileceğini veya uygulama dışına kopyalayabileceğini unutmayın. Bu
        nedenle bir başkasının kaydetmesini veya paylaşmasını istemeyeceğiniz
        içerikler paylaşmayın.
      </p>

      <p>
        <strong>Üçüncü Taraflar ve Entegrasyonlar</strong>
      </p>

      <p>
        Üçüncü taraflardan ve entegrasyon ortaklarından şu amaçlarla bilgi
        toplayıp kullanabiliriz.
      </p>

      <ul>
        <li>
          Facebook ve Google gibi üçüncü taraf entegrasyonlarıyla hizmetimize
          kaydolmanızı sağlayabiliriz.
        </li>

        <li>
          Hizmetlerimizi size göre özelleştirmek ve diğer hizmetlerle birlikte
          kullanabilmenizi sağlayabiliriz.
        </li>
      </ul>

      <p>
        <strong>Bilgilerin Kullanım Amaçları</strong>
      </p>

      <p>Topladığımız bilgileri, şu amaçlar için kullanırız:</p>

      <ol type="1">
        <li>Sizi veya cihazınızı Hizmetlerimize kaydetmeye yardım etmek,</li>

        <li>Talep ettiğiniz bir Hizmeti veya özelliği sunmak,</li>

        <li>
          Sadece bize ayrıca onayınızı verdiğiniz hallerde doğrudan pazarlama
          iletişimleri yoluyla tanıtımları ve teklifleri sunmak,
        </li>

        <li>Pazar araştırması sağlamak üzere hizmetlerimizi analiz etmek,</li>

        <li>
          Ürünlerimiz ve hizmetlerimiz hakkında görüşlerinizi istemek ve
          gerekirse ayrıca onayınızı alarak müşteri anketlerini yapmak,
        </li>

        <li>
          Ürünlerimizi ve hizmetlerimizi geliştirmek, işletmek, iyileştirmek,
          sunmak, sürdürmek ve korumak,
        </li>
      </ol>

      <ol type="1">
        <li>
          İzin verilen e-posta veya SMS dahil olmak üzere bildirim
          göndermek,&nbsp;e-posta ile anket yanıtlamaları veya formunuzu
          doldurmaları için birilerini kolaylıkla davet etmenizi sağlamak,
        </li>

        <li>
          Kimliğinizi doğrulamak ve dolandırıcılık veya diğer yetkisiz ya da
          yasa dışı işlemleri engellemek,
        </li>

        <li>
          Kullanım Şartlarımızı ve diğer kullanım politikalarımızı ihlal eden
          davranışlar hakkında yasal işlem başlatmak, bunları soruşturmak ve
          rapor etmek, kolluk kuvvetlerinden gelen taleplere yanıt vermek ve
          yasal zorunluluklara uymak.
        </li>
      </ol>

      <p>
        <strong>Bilgi Paylaşımı</strong>
        <strong></strong>
      </p>

      <p>Üçüncü taraflarla aşağıdaki durumlarda bilgi paylaşabiliriz:</p>

      <ul>
        <li>
          Youreply platformu üzerinden araştırma yapmak isteyen bireyler ve
          kuruluşlar ile bilgilerinizi&nbsp;paylaşabiliriz.
        </li>

        <li>
          Hakkınızdaki bilgileri, hizmet ve işlev sağlayan iş ortaklarıyla
          paylaşabiliriz.
        </li>

        <li>
          Cihaz ve kullanım bilgileri gibi sizinle ilgili bilgileri,
          dolandırıcılığı önlememize ve başkalarının önlemesine yardımcı olmak
          için paylaşabiliriz.
        </li>

        <li>
          Hakkınızdaki bilgileri yasal nedenlerle, emniyet ve güvenlik
          nedenleriyle paylaşabiliriz.
        </li>

        <li>
          Şirket çalışanları tarafından verilerin işlenmesi ve kontrol edilmesi
          gerekliliği kapsamında şirket çalışanları ile paylaşabiliriz.
        </li>

        <li>
          Hesap bilgileriniz ve verileriniz birincil yönetici(ler) ile
          paylaşabiliriz. Anket verileriniz ise anket paylaştığınız veya
          işbirliği yaptığınız ekip üyeleri tarafından görüntülenebilir.
        </li>

        <li>
          Hizmetlerimizin belirli unsurlarını bize sağlamaları, özellikle
          yanıtlayanlara e-posta ile anket göndermek için, ödemelerin yapılması
          için, pazarlama ve reklam için, web sitesinin geliştirilmesi için
          bağlı kuruluşlarımızdan ve güvenilir ortaklarımızdan faydalanmaktayız.
        </li>
      </ul>

      <p>
        <strong>Bilgi Güvenliği</strong>
      </p>

      <p>
        Firmamız ile paylaşmış olduğunuz verilerin güvenliği için tüm
        birimlerimiz titizlikle çalışmaktadır. &nbsp;Hizmetler ile bağlantılı
        olarak topladığımız bilgilerin güvenliğini sağlamak için fiziksel ve
        teknik olarak tüm güvenlik önlemleri almaktayız.
      </p>

      <p>
        Ancak bilgilerinizi korumak için tüm önlemleri alsak da hiçbir web
        sitesi, mobile uygulama, internet iletimi, bilgisayar sistemi veya
        kablosuz bağlantının tamamen güvenli olmadığını belirtmek isteriz.
      </p>

      <p>
        Şirket bünyesinde gerçekleştirilen kişisel veri işleme faaliyetleri
        düzenli olarak denetlenmektedir.
      </p>

      <p>
        <strong>Haklarınız</strong>
      </p>

      <ul>
        <li>İşlenip işlenmediğini öğrenme.</li>

        <li>İşlenmişse buna ilişkin bilgi talep etme.</li>

        <li>
          İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme.
        </li>

        <li>
          Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
          kişileri bilme.
        </li>

        <li>
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme.
        </li>

        <li>
          Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel
          verilerin silinmesini veya yok edilmesini isteme.
        </li>

        <li>
          Söz konusu Kanun’un ilgili maddeleri uyarınca yapılan işlemlerin,
          kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme.
        </li>

        <li>
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz
          etme.
        </li>

        <li>
          Kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
          zararın giderilmesini talep etme haklarına sahipsiniz.
        </li>
      </ul>

      <p>
        <strong>Bilgilerinizin Saklanma Süresi</strong>
      </p>

      <ul>
        <li>
          Verileriniz bir hizmetin sunulması/sağlanması için kullanılan bilgiler
          kapsamında gerekli asgari süre,
        </li>

        <li>
          Kanun veya sözleşme kapsamında gerekli görüldüğünde veya yasal
          yükümlülüklerimizle ilgili olarak,
        </li>

        <li>
          Sadece toplanma amacı için gerektiği sürece, herhangi bir sözleşme
          kapsamında veya geçerli kanunlar veya istatistik amaçları için
          gerekmesi halinde daha uzun süreler boyunca uygun önlemlere tabi
          olması kaydıyla,
        </li>

        <li>Silinmesini talep ettiğiniz ana kadar,</li>
      </ul>

      <p>toplanmakta ve saklanmaktadır.</p>

      <p>
        Online Anketler&#8217;de bir hesabınız olduğunda, hesabınızdaki veriler
        silinmez. Bu veriyi tutmak ve verilerin tutulacağı süreleri kontrol
        etmek sizin sorumluluğunuzdadır. Hesabınızda verileri silebileceğiniz
        kontroller bulunur. Bir yanıtlayan olmanız durumunda, yanıtlarınızın
        Online Anketler hizmetlerinde ne kadar süre saklanacağını anket
        oluşturucusuna sormanız gerekir.
      </p>

      <p>
        Silme işleminin belirli bir zaman alabileceğini unutmayınız.
        Verilerinizi yasal gereklilikler, kötüye kullanım, Kullanım Şartları
        ihlalleri söz konusu silme işlemlerini askıya almamız gerekebilir.
      </p>

      <p>
        <strong>Bilgileriniz Üzerindeki Kontrolünüz</strong>
      </p>

      <ul>
        <li>
          Bilgilerinizi Youreply platformu üzerinden değiştirebilir veya
          silinmesini isteyebilirsiniz. Kişisel bilgilerinize erişmeden veya bu
          bilgileri güncellemeden önce kimliğinizi doğrulamanızı veya ilave
          bilgi sağlamanızı isteyebiliriz. Kişisel bilgilerinize erişme ve
          bunları güncelleme talebinizi çeşitli nedenlerle, örneğin talebinizin
          diğer kullanıcıların gizliliğini tehlikeye atması veya yasa dışı
          olması durumunda reddedebiliriz.
        </li>

        <li>
          Platform üzerinden vermiş olduğunuz izinleri geri çekebilirsiniz.
        </li>
      </ul>

      <p>
        <strong>Uluslararası Veri Transferleri</strong>
      </p>

      <p>
        Kişisel bilgileriniz yaşadığınız ülke dışındaki diğer ülkelerden
        toplanabilir, bu ülkelere aktarılabilir, bu ülkelerde saklayabilir ve
        işleyebiliriz. Kişisel bilgilerinize Youreply platformunu kullanan ve
        yaşadığınız ülke dışındaki ülkelerde yaşayan araştırma yapmak isteyen
        birey ve kuruluşlar ulaşabilir.
      </p>

      <p>
        <strong>Çerezler, İşaretler ve Benzer Teknolojiler</strong>
      </p>

      <p>
        Hizmetlerimizdeki içerik, reklam veya başka işlevleri sunan üçüncü
        taraflar, verilen hizmetteki bazı alanlarda çerezler, işaretler,
        pikseller ve başka teknolojiler kullanabilecektir. &nbsp;
      </p>

      <p>
        <strong>Çerezler;</strong> bilgisayarınıza, televizyonunuza, cep
        telefonunuza ve diğer cihazlarınıza bilgi kaydeden küçük dosyalardır.
        Cihazınıza çerez yükleyen kurumun farklı web siteleri, hizmetler,
        cihazlar ve tarama oturumlarında sizi tanımasını sağlarlar. Tanımlama
        bilgileri, pek çok yararlı amaca hizmet eder. Örneğin:
      </p>

      <ul>
        <li>
          Çerezler, oturum açma bilgilerinizi hatırlayabildiğinden bir hizmette
          her oturum açtığınızda kimlik bilgilerini girmeniz gerekmez.
        </li>

        <li>
          Çerezler, bizim ve üçüncü tarafların, Hizmetlerin en çok hangi
          kısımlarının kullanıldığını anlamamıza yardımcı olur, çünkü
          ziyaretçilerin hangi sayfalara ve özelliklere eriştiğini ve sayfalarda
          ne kadar zaman harcadığını görmemize yardım eder. Bu tür bilgileri
          incelemek Hizmetleri daha iyi bir şekilde uyarlamamıza ve size daha
          iyi bir deneyim sunmamıza yardımcı olmaktadır.
        </li>

        <li>
          Çerezler, bizim ve üçüncü tarafların, bir Hizmete her eriştiğinizde
          aynı reklamı almamanız için önceden gördüğünüz reklamları bilmemizi
          sağlar.
        </li>

        <li>
          Çerezler, hizmetlerimizi ve diğer web siteleri ile uygulamaları
          kullanımınız hakkındaki bilgileri toplayarak bizim ve üçüncü
          tarafların size ilgili içeriği ve reklamları sunmamıza yardımcı olur.
        </li>

        <li>
          Çerezler, güvenliğin sağlanması, sizlere kişiselleştirilmiş içerik
          sunulması
          <br />
          (kullanıcı tercihleri, varsayılan dil, cihaz ve tarayıcı bilgileri,
          hizmeti kullanma düzeyinizi ve ziyaret ettiğiniz sitemizdeki web
          sayfalarını içeren profil bilgilerinizin kaydı) amacını
          gerçekleştirmemize yardımcı olur.
        </li>
      </ul>

      <p>
        Hizmetlere erişmek için bir web tarayıcısı kullanırken tarayıcıyı, tüm
        çerezleri kabul edecek, hepsini reddedecek veya bir çerez
        gönderildiğinde sizi bilgilendirecek şekilde yapılandırabilirsiniz. Her
        tarayıcı farklıdır; bu nedenle çerez tercihlerinizi nasıl
        değiştireceğinizi öğrenmek için tarayıcınızın yardım menüsüne göz
        atabilirsiniz. Cihazınızın işletim sistemi, ek çerez kontrolleri
        içerebilir. Bazı Hizmetlerin çerezleri kullanarak çalışacak şekilde
        tasarlanabileceğini ve çerezleri devre dışı bırakmanın, bu Hizmetleri
        veya bunların belirli kısımlarını kullanma olanağınızı
        etkileyebileceğini lütfen unutmayın.
      </p>

      <p>
        Anket yanıtladığınız sırada, çerezler yoluyla bilgi toplanır. Bu
        çerezler, anketin tüm işlevlerini yerine getirdiğinden ve anketin uygun
        ve ideal şekilde işlediğinden emin olmak için kullanılır.&nbsp;
      </p>

      <p>
        <strong>Bizimle İletişime Geçin</strong>
      </p>

      <p>
        <strong>Anket oluşturucu iseniz; </strong>Herhangi bir Youreply
        hizmetinde hesabınız varsa, sizinle ilgili olarak tuttuğumuz tüm kişisel
        verilerin bir kopyasına hak kazanırsınız. Ayrıca verilerinizi kullanma
        şeklimizi kısıtlamamızı talep etme veya verilerinizi işleme şeklimizin
        bazı yönlerine itiraz etme hakkına da sahipsiniz. Giriş yaptığınızda
        kendi hesabınızdaki birçok veriye ulaşabilirsiniz. Ancak tüm
        verilerinizin tam bir kopyasını almak istiyorsanız veya verilerinizi
        kullanma şeklimizle ilgili bir kısıtlama/kısıtlama talep etmek
        istiyorsanız bizimle iletişime geçmelisiniz.
      </p>

      <p>
        <strong>Yanıtlayan </strong>
        <strong>iseniz;</strong> Bir Online Anketler hizmetini kullanan anket
        oluşturucu tarafından size gönderilen bir anketi, formu veya başvuruyu
        yanıtladığınızda, yanıtlarınızda sunduğunuz bilgilerin yönetilmesi,
        silinmesi, bunlara erişilmesi veya erişimin kısıtlanması ya da
        bilgilerin kullanımına ilişkin onayın geri alınması konularını görüşmek
        için doğrudan söz konusu bireye veya kuruma ulaşmanız gerekir. Online
        Anketler yanıt verilerini kontrol etmez ve söz konusu verilere ilişkin
        bu talepleri doğrudan değerlendirecek bir pozisyona sahip değildir. Bu
        anket oluşturucuyu bulmak konusunda zorluk yaşıyorsanız, destek ekibimiz
        aracılığıyla bize ulaşabilirsiniz.
      </p>

      <p>
        <strong>Ziyaretçi iseniz; </strong>Web sitemizi ziyaret ettiğinizde
        yukarıdaki haklardan herhangi birini kullanmak istemeniz durumunda
        lütfen buradan destek ekibimize ulaşın.
      </p>

      <p>
        Gizlilik Tercihlerinizi güncellemek, bilgilerinizi düzeltmek, talep
        göndermek veya bize sorular sormak için bizimle iletişime
        geçebilirsiniz. Bizimle iletişime geçebilmenin en kolay yolu
        info@youreply.com.tr üzerinden bizimle irtibat kurmanızdır. Bize ayrıca
        <span> </span>
        <a href="https://www.youreply.com.tr/kvkk">
          https://www.youreply.com.tr/kvkk
        </a>
        <span> </span>
        adresinden ulaşabilirsiniz.
      </p>
    </>
  );
}

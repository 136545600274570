import React from 'react';

interface UserContactProps {
  userName: string;
  userIdentity: string;
  userAddress: string;
  userEmail: string;
  userPhone: string;
}

export default function UserContact({
  userName,
  userIdentity,
  userAddress,
  userEmail,
  userPhone,
}: UserContactProps) {
  return (
    <>
      <p>
        <strong>1 &#8211; TARAFLAR</strong>
      </p>

      <p>
        <strong>HİZMET SAĞLAYICI</strong>
      </p>

      <p>
        <strong>1 &#8211; TARAFLAR</strong>
      </p>

      <p>
        <strong>SATICI</strong>
      </p>

      <p>
        Adı &#8211; Soyadı / Unvanı : Youreply Teknoloji Ticaret Eğitim
        Danışmanlık Araştırma Organizasyon Anonim Şirketi
      </p>

      <p>Vergi Kimlik Numarası / Ticaret Sicil Numarası: 9821041003/495271</p>

      <p>
        Adresi: Hacettepe Üniversitesi Teknoloji Geliştirme Bölgesi, Beytepe Kampüsü, 6. Ar-Ge Binası C Blok, Çankaya / Ankara
      </p>

      <p>Telefon Numarası: 05322266894</p>

      <p>E-posta Adresi: info@youreply.com.tr</p>

      <p>Websitesi: www.youreply.com.tr</p>

      <p>
        <strong>ALICI</strong>
      </p>

      <p>Adı &#8211; Soyadı / Unvanı: {userName}</p>

      <p>TC Kimlik/Vergi No: {userIdentity}</p>

      <p>Adresi: {userAddress}</p>

      <p>Telefon: {userPhone}</p>

      <p>E-Posta: {userEmail}</p>

      <p>
        <strong>ARAŞTIRMACI</strong>
      </p>

      <p>Adı – Soyadı :</p>

      <p>TC Kimlik/Vergi No:</p>

      <p>Adresi :</p>

      <p>Telefon :</p>

      <p>E-Posta :</p>

      <p>
        <strong>2-SÖZLEŞMENİN KONUSU VE KAPSAMI</strong>
      </p>

      <p>
        <strong>2.1-</strong> İşbu Mesafeli Satış Sözleşmesi (“
        <strong>Sözleşme</strong>”) 6502 Sayılı Tüketicinin Korunması Hakkında
        Kanun (“<strong>Kanun</strong>”) ve Mesafeli Sözleşmeler Yönetmeliği’ne
        uygun olarak düzenlenmiştir. İşbu Sözleşme’nin tarafları işbu Sözleşme
        tahtında Kanun’dan ve Mesafeli Sözleşmeler Yönetmeliği’nden kaynaklanan
        yükümlülük ve sorumluluklarını bildiklerini ve anladıklarını kabul ve
        beyan ederler.
      </p>

      <p>
        <strong>2.2-</strong> İşbu Sözleşme’nin konusunu; KULLANICI’nın,
        Youreply Teknoloji Ticaret Eğitim Danışmanlık Araştırma Organizasyon
        Anonim Şirketi’ne ait{' '}
        <a href="https://shop.akgun.com.tr/">https://ww.youreply.com.tr/</a>{' '}
        alan adlı web sitesinden (“<strong>Websitesi</strong>”), HİZMET
        SAĞLAYICI tarafından sunulan hizmetlerin alınmasına yönelik Kanun ve
        Mesafeli Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve
        yükümlülüklerinin belirlenmesi oluşturur.
      </p>

      <p>
        <strong>3-HİZMETİN İFASI</strong>
      </p>

      <p>
        <strong>3.1-</strong> KULLANICI, YouReply nezdinde ARAŞTIRMACI
        tarafından paylaşılan çevrim içi araştırmalarda katılımcı sıfatıyla yer
        almayı seçtiğinde ödeme karşılığında çalışmada yer alan soruların
        cevaplarını kanuna ve ahlaka uygun olarak kötüye kullanmamak kaydı ile
        göndermeyi kabul, beyan ve taahhüt eder. KULLANICI, vermiş olduğu
        bilgiler kapsamında uygun olduğu sistem tarafından belirlenen
        araştırmalar haricinde yer alan araştırmalara katılım sağlamaya hak
        kazanmadığını peşinen kabul, beyan ve taahhüt eder.
      </p>

      <p>
        <strong>3.2-</strong> HİZMET SAĞLAYICI, YouReply’e üye olup çevrim içi
        araştırma bağlantısı üzerinden anket cevaplandıran ve araştırmayı
        tamamlayan KULLANICI’nın verdiği cevaplar ARAŞTIRMACI tarafından
        onaylandıktan sonra ödemelerinin katılımcı tarafından bildirilen banka
        hesabına yapılmasını sağlamakla yükümlüdür.
      </p>

      <p>
        <strong>
          4- SÖZLEŞME KONUSU MAL VE HİZMETİN TEMEL NİTELİKLERİ VE FİYATI (KDV
          DAHİL)
        </strong>
      </p>

      <p>
        YouReply, anket formlarını dikkatli ve içtenlikle tamamlayan
        katılımcıların aşağıda belirtilen minimum tutarların altında ödeme
        almayacaklarını garanti etmektedir. Ayrıca, ARAŞTIRMACI her zaman daha
        yüksek katılımcı ücreti belirleme imkanına sahip olup ücret değişkenlik
        göstermektedir.
      </p>

      <p> Tamamlama Süresi Ücret</p>

      <p> &lt; 5dk ₺20</p>

      <p> 5dk &#8211; 10dk ₺30</p>

      <p> 10dk &#8211; 15dk ₺40</p>

      <p> 15dk &#8211; 20dk ₺50</p>

      <p> &gt; 20dk Özel fiyatlar</p>

      <p>
        <strong>5-SORUMSUZLUK ANLAŞMASI</strong>
      </p>

      <p>
        <strong>5.1-</strong> KULLANICI, HİZMET SAĞLAYICI’nın anket sorularını
        denetlenme yükümlülüğü bulunmadığını, bu nedenle anket sorularının
        ahlaka ve kanuna aykırı nitelikte olması halinden SATICI’nın
        sorumluluğunun bulunmadığını kabul, beyan ve taahhüt eder.
      </p>

      <p>
        <strong>5.2-</strong> KULLANICI, anket sahibi ARAŞTIRMACI ile arasındaki
        iletişimden kaynaklanabilecek olumsuz durumlardan ve zarardan HİZMET
        SAĞLAYICI’nın sorumlu olmadığını kabul, beyan ve taahhüt eder.
      </p>

      <p>
        <strong>5.3-</strong> KULLANICI, ARAŞTIRMACI’nın bağlantısında yer alan
        soruların toplam cevaplanma süresinin adil bir şekilde belirlenmesi ve
        katılımcıya gerekli bilgi verilmesi sorumluluğunun tamamen
        ARAŞTIRMACI’ya ait olduğunu, bu hususta HİZMET SAĞLAYICI’nın sorumlu
        olmadığını kabul, beyan ve taahhüt eder.
      </p>

      <p>
        <strong>5.4-</strong> KULLANICI, ankete vermiş olduğu cevaplarının
        kontrol edilip onaylanıp onaylanmaması durumunun tamamen ARAŞTIRMACI’nın
        sorumluluğunda olduğunu, cevaplarının onaylanmaması halinden HİZMET
        SAĞLAYICI’nın sorumlu olmadığını kabul ve beyan eder. KULLANICI,
        ARAŞTIRMACI’nın aşağıdaki durumlardan birinin mevcudiyeti halinde
        KULLANICI’nın vermiş olduğu cevapları kesin red hakkı olduğunu peşinen
        kabul, beyan ve taahhüt eder:
      </p>

      <ul>
        <li>Çalışmayı makul sayılamayacak kadar kısa sürede tamamlaması.</li>

        <li>
          Anketin temelini oluşturan ve ARAŞTIRMACI tarafından cevaplanması
          zorunlu soru olarak gösterilen soruları cevaplamaması.
        </li>

        <li>
          ARAŞTIRMACI tarafından hazırlanan dikkat kontrol sorularına
          yanlış/tutarsız cevaplar vermesi.
        </li>

        <li>
          Araştırmada yer alan soruları cevaplamak için yeterince çaba
          göstermediğini tespit edilmemesi.
        </li>
      </ul>

      <p>
        <strong>6-KULLANICININ BEYAN VE TAAHHÜTLERİ</strong>
      </p>

      <p>
        <strong>6.1-</strong> KULLANICI, Websitesi’nde yer alan Sözleşme konusu
        ürünün temel nitelikleri, fiyatı ve ödeme şekline ilişkin olarak HİZMET
        SAĞLAYICI tarafından yüklenen ön bilgileri okuyup bilgi sahibi olduğunu
        ve elektronik ortamda gerekli teyidi verdiğini beyan eder.
      </p>

      <p>
        <strong>6.2-</strong> KULLANICI tüketici sıfatıyla talep ve
        şikayetlerini yukarıda yer alan HİZMET SAĞLAYICI iletişim bilgilerini
        kullanarak ve/veya Websitesi’nde iletişim bilgilerinde yer alan müşteri
        hizmetleri, mail adresi üzerinden ve şirket adresinden ulaştırabilir.
      </p>

      <p>
        <strong>6.3-</strong> KULLANICI, işbu Sözleşme’yi ve Ön Bilgilendirme
        Formu’nu elektronik ortamda teyit etmekle, mesafeli sözleşmelerin
        akdinden önce HİZMET SAĞLAYICI tarafından KULLANICI’ya verilmesi gereken
        adres, hizmetin temel özellikleri, fiyatı ve ödeme bilgilerini de doğru
        ve eksiksiz olarak edindiğini teyit etmiş olur.
      </p>

      <p>
        <strong>7-HİZMET SAĞLAYICININ BEYAN VE TAAHHÜTLERİ</strong>
      </p>

      <p>
        HİZMET SAĞLAYICI tarafından verilen hizmetin sonuçlarından dolayı
        oluşabilecek maddi ve manevi tüm zararlardan HİZMET SAĞLAYICI hiçbir
        suretle sorumlu değildir. Edinilen veya erişilen hiçbir bilginin
        doğruluğu veya güvenilirliğinden sorumlu tutulamaz.
      </p>

      <p>
        <strong>8-SORUMLULUKLAR</strong>
      </p>

      <p>
        <strong>8.1-</strong> KULLANICI veyahut üçüncü bir kişi/kişiler
        tarafından yapılan her işlem ve eylemdeki hukuki, cezai ya da sair her
        türlü sorumluluk KULLANICI’ya ait olup KULLANICI, hizmetten
        faydalanırken kanuna ve mevzuat hükümlerine uyulacağını, kanuna ve
        mevzuat hükümlerine uyulmaması halinde gerek HİZMET SAĞLAYICI’nın
        gerekse üçüncü kişilerin uğrayacağı her türlü zarara ilişkin
        sorumluluğun bizzat kendisine ait olacağını kabul, beyan ve taahhüt
        eder.
      </p>

      <p>
        <strong>8.2-</strong> KULLANICI hizmetin haksız ve hukuka aykırı
        kullanılmasından dolayı HİZMET SAĞLAYICI ve üçüncü şahısların uğrayacağı
        zarar ve ziyanları tazmin edeceğini, bu sorumluluklar nedeni ile HİZMET
        SAĞLAYICI’dan herhangi bir talepte bulunmayacağını, HİZMET SAĞLAYICI’ya
        yöneltilebilecek olası talep ve zararların derhal tazmin edeceğini
        kabul, beyan ve taahhüt eder.
      </p>

      <p>
        <strong>8.3-</strong> HİZMET SAĞLAYICI, hizmet esnasında sağlanan içerik
        ve sonuçların hatalı, hukuka aykırı işlerde veya kanuna aykırı olarak
        kullanılması dâhil her türlü kullanım nedeniyle ortaya çıkabilecek
        maddi, manevi, hukuki, mali sonuçlardan veya kazanç kayıpları gibi
        doğudan ve dolaylı zararlar ile üçüncü kişilerin uğrayabilecekleri veya
        uğradıkları zararlardan sorumlu tutulamaz. Bu kapsamda HİZMET
        SAĞLAYICI’ya herhangi bir sorumluluk yüklenmesi durumunda buna sebebiyet
        veren KULLANICI, HİZMET SAĞLAYICI’nın her türlü zararını derhal tazmin
        edeceğini kabul, beyan ve taahhüt eder.
      </p>

      <p>
        <strong>9&#8211;CAYMA HAKKI</strong>
      </p>

      <p>
        Hizmetin niteliği gereği KULLANICI cayma hakkına sahip olmadığını kabul
        ve beyan eder. <strong></strong>
      </p>

      <p>
        <strong>10&#8211;UYUŞMAZLIKLARIN ÇÖZÜMÜ</strong>
      </p>

      <p>
        İş bu Mesafeli Sözleşme ile ilgili çıkacak ihtilaflarda; her yıl Gümrük
        ve Ticaret Bakanlığı tarafından ilan edilen değere kadar Alıcı’nın
        yerleşim yerindeki ürünü satın aldığı veya ikametgâhının bulunduğu
        yerdeki İl veya İlçe Tüketici Sorunları Hakem Heyetleri, söz konusu
        değerin üzerindeki ihtilaflarda ise Tüketici Mahkemeleri yetkilidir.
      </p>

      <p>
        <strong>11-BİLDİRİMLER VE DELİL SÖZLEŞMESİ</strong>
      </p>

      <p>
        İşbu Sözleşme tahtında taraflar arasında yapılacak her türlü yazışma,
        mevzuatta sayılan zorunlu haller dışında, Websitesinde yer alan iletişim
        bilgileri üzerinden yapılacaktır. KULLANICI, işbu Sözleşme’den
        doğabilecek ihtilaflarda HİZMET SAĞLAYICI’nın resmi defter ve ticari
        kayıtlarıyla, kendi veri tabanında, sunucularında tuttuğu elektronik
        bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır
        delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193.
        maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve
        taahhüt eder.
      </p>

      <p>
        <strong>12-YÜRÜRLÜK</strong>
      </p>

      <p>
        12 (on iki) maddeden ibaret bu Sözleşme, taraflarca okunarak, ……………..
        tarihinde, KULLANICI tarafından elektronik ortamda onaylanmak suretiyle
        akdedilmiş ve yürürlüğe girmiştir.
      </p>

      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>HİZMET SAĞLAYICI</td>
            <td>KULLANICI</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </>
  );
}

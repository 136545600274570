import React from 'react';

export default function TermContact() {
  return (
    <>
      <p>
        Bu Kullanıcı Sözleşmesi (“Sözleşme”) merkezi Hacettepe Üniversitesi
        Teknoloji Geliştirme Bölgesi, Beytepe Kampüsü, 6. Ar-Ge Binası C Blok,
        Çankaya / Ankara olan, Youreply Teknoloji Ticaret Eğitim Danışmanlık
        Araştırma Organizasyon Anonim Şirketi
        <span> </span>
        <strong>(Bundan sonra “YOUREPLY” olarak anılacaktır.)</strong>
        <span> </span>
        ile YOUREPLY’ın sahip olduğu https://www.youreply.com.tr adresinde
        bulunan siteyi ziyaret eden veya üye olan
        kişiler/müşterilerimiz/katılımcılar/anket oluşturucuları
        <span> </span>
        <strong>(Bundan sonra “Kullanıcı” olarak anılacaktır.) </strong>
        <span> </span>
        arasında aşağıdaki şartlarda akdedilmiştir.
      </p>

      <p>
        Kullanıcılar, https://www.youreply.com.tr adresinde bulunan siteyi
        ziyaret ederek, araştırmacı veya katılımcı sıfatıyla üye olarak bu
        Sözleşme’deki şartları kabul etmiş sayılmaktadır. Kullanıcılar, siteyi
        kullanırken bu Sözleşme’ye, YOUREPLY tarafından yayınlanacak olan diğer
        kullanım koşullarına ve Türkiye Cumhuriyeti mevzuatına uygun davranmayı
        taahhüt eder. Aksi halde; tüm hukuki ve cezai sorumluluk Kullanıcı’ya
        aittir.
      </p>

      <p>
        Kullanıcılar tanımlanırken, adı, soyadı, TC Kimlik Numarası, e-posta
        adresleri üyelik ekranına girilir. Sitemize girilen bilgilerin ve
        işlemlerin güvenliği için bilgi ve işlemin mahiyetine göre ve tarafların
        yasal sorumlulukları çerçevesinde gerekli önlemler, Youreply Teknoloji
        Ticaret Eğitim Danışmanlık Araştırma Organizasyon Anonim Şirketi ve/veya
        ilgili banka ve ödeme kuruluşlarının sistem ve internet altyapısında
        alınmıştır. YOUREPLY’ın kişisel verileriniz ile ilgili uyguladığı
        Gizlilik ilkeleri için, web sitesinde yer alan Gizlilik Politikası
        hükümleri geçerlidir.
      </p>

      <p>
        İşbu web sitesinde yapılan işlemler YOUREPLY tarafından yapılmakta olup;
        fatura ve satışla bağlantılı diğer her türlü belge (Mesafeli Satış
        Sözleşmesi, Ön Bilgilendirme Formu vd.) YOUREPLY tarafından
        düzenlenmektedir. Satışlar kapsamında Kullanıcı’nın kredi kartı, sanal
        kart, hesap bilgisi ve sair bankacılık verisine hiçbir taht altında
        YOUREPLY erişim sağlayamaz ve tüm ödeme işlem ve onayları YOUREPLY’dan
        bağımsız olarak banka ve ödeme kuruluşları tarafından çevrimiçi
        (=online) olarak gerçekleştirilir.
      </p>

      <p>
        Kullanıcı, üyelik sistemi üzerinden üye olurken vermiş olduğu tüm
        bilgilerin doğru ve tam olduğunu, yanıltıcı olmadığını, bunların yanlış,
        eksik veya yanıltıcı olmasından kaynaklanabilecek her türlü zarardan ve
        yaptırımdan münhasıran sorumlu olduğunu, YOUREPLY’ın bu yönde
        uğrayabileceği doğrudan, dolaylı ve her türlü zararı nakden ve derhal
        tazmin edeceğini kabul, beyan ve taahhüt eder. Kullanıcı, kendisi
        tarafından belirlenen ve kendisine münhasır kullanıcı adı ve şifrenin
        gizliliğinden sorumlu olduğunu, bunların kendi kusuru veya ihmali
        neticesinde üçüncü kişilerin eline geçmesi ve/veya bu sebeple
        uğrayabileceği her türlü zarar dolayısıyla YOUREPLY’ın herhangi bir
        sorumluluğu olmadığını kabul, beyan ve taahhüt eder.
      </p>

      <p>
        Kullanıcı, 18 yaşından büyük ve Türk Medeni Kanunu uyarınca reşit
        olduğunu kabul, beyan ve tekeffül eder.
      </p>

      <p>
        Kullanıcı, site kullanımı kapsamında ahlaka ve kanuna aykırı
        davranışlarda bulunmayacağını, ahlaka ve kanuna aykırı veriler/sorular
        paylaşmayacağını kabul, beyan ve taahhüt eder. Kullanıcının bu maddeye
        aykırı paylaşımlarda bulunmasından kaynaklı YOUREPLY’ın herhangi bir
        sorumluluğunun bulunmadığını, bu eylemden doğan her türlü zarardan ve
        yaptırımdan münhasıran sorumlu olduğunu, YOUREPLY’ın bu yönde
        uğrayabileceği doğrudan, dolaylı ve her türlü zararı nakden ve derhal
        tazmin edeceğini kabul, beyan ve taahhüt eder.
      </p>

      <p>
        Kullanıcılar arasında gerçekleşen iletişim sorunlarından YOUREPLY’ın
        herhangi bir sorumluluğu bulunmamaktadır.
      </p>

      <p>
        Hizmetlerimizdeki çoğu içerik kullanıcılar ve diğer üçüncü şahıslar
        tarafından üretilmektedir. Söz konusu içeriğin herkese açık bir biçimde
        yayımlanmış veya özel olarak gönderilmiş olmasına bakılmaksızın, içeriğe
        dair yegane sorumluluk onu gönderen kişi veya kuruma aittir. YOUREPLY,
        Hizmetlerde görünen tüm içeriği gözden geçirme, denetleme veya kaldırma
        hakkını saklı tutsa da tüm içeriği gözden geçirme sorumluluğu
        bulunmamaktadır.
      </p>

      <p>
        Kullanıcı’nın siteden faydalanabilmesi için gerekli olan kullanıcı adı
        ve şifre kişiye özeldir. Bu minvalde, Kullanıcı, kendisine tahsis edilen
        kullanıcı adı ve şifre aracılığıyla YOUREPLY’ın yalnızca kendisi
        tarafından kullanılacağını kabul, beyan ve taahhüt eder. Kullanıcı’nın
        kullanıcı adının ve şifresinin Kullanıcı dışındaki üçüncü kişilerce
        kullanılmasından kaynaklı tüm sorumluluk kendisine ait olup, bu şekilde
        kullanıldığının tespit edilmesi halinde, üyelik iptal edilir ve YOUREPLY
        kullanımı kapatılır.
      </p>

      <p>
        Kullanıcıya ait bilgiler, yasal düzenlemelerin öngördüğü
        sorumluluklarımız ve YOUREPLY Gizlilik Politikası çerçevesinde toplanır,
        işlenir ve paylaşılabilir. Ayrıca üyelikleri ve/veya form doldurma,
        araştırma anketi yükleme sırasında vermiş oldukları iletişim bilgileri
        ve diğer bilgiler Sitemize üyelik işlemlerinizin yapılması,
        güncellenmesi, muhtelif ürün/hizmetlerin YOUREPLY ve iş ortakları ve
        tedarikçileri tarafından verilebilmesi/satımı, ürün/hizmet ücret-masrafı
        tahsilatları ve çeşitli tanıtım-reklam, promosyon, iletişim ve
        satış-pazarlama uygulamaları için ilgili yasal mevzuat ve tarafınızca
        verilen onaya istinaden kayda alınabilir, YOUREPLY ve belirtilen
        kuruluşlar nezdinde saklanabilir, işlenebilir, yine tarafınızca verilen
        onay dahilinde gerekli görülen hallerde/yerlerde paylaşılabilir ve
        kullanılabilir.
      </p>

      <p>
        Kullanıcı YOUREPLY tarafından kendisine işbu Sözleşme konusu ile ilgili
        olarak gönderilecek her türlü ticarî elektronik iletilere onay
        vermektedir. Bu kapsamda Kullanıcı, YOUREPLY tarafından kendisine, kişi
        müdahalesi olmadan çalışan elektronik posta, kısa mesaj gibi otomatik
        arama sistemleri vasıtasıyla ya da başkaca iletişim vasıtaları ile
        Kullanıcı’dan işbu madde kapsamında alınan açık ve yazılı izne ek olarak
        herhangi bir izin alınmaksızın bilgilendirme, pazarlama veya reklam
        amacıyla her türlü elektronik ileti, kısa mesaj vs. gönderilebileceğini
        kabul ve beyan eder. Kullanıcı’nın ilgili ticari elektronik iletileri
        almak istememesi halinde, Kullanıcı her zaman bu onayını geri alabilir.
      </p>

      <p>
        YOUREPLY, site üzerinden, YOUREPLY’ın kendi kontrolünde olmayan üçüncü
        kişilerin işlettiği internet sitelerine ve/veya portallara, dosyalara
        veya içeriklere uzantı (=link) verebilir. Bu uzantılar, ilgili internet
        sitesini veya siteyi işleten kişiyi desteklemek amacı veya web sitesini
        ya da içerdiği bilgilere yönelik herhangi bir açık veya zımni beyan veya
        garanti niteliği taşımamaktadır. Site üzerindeki uzantılar vasıtasıyla
        erişilen internet siteleri, dosyalar ve içerikler, web sitelerinden
        sunulan hizmetler, ürünler ya da bunların içeriği hakkında YOUREPLY’ın
        hiçbir sorumluluğu&nbsp; bulunmamaktadır. Sitemizin diğer internet-web
        sitelerine bağlantı verdiği hallerde tüm kullanım ve işlemler için o
        sitelere ait gizlilik-güvenlik politikası ve kullanım şartları
        geçerlidir; Sitemizden reklam, banner, içerik görmek veya başka herhangi
        bir amaç ile ulaşılan diğer web sitelerinden bilgi kullanımları, keza
        sitelerin etik ilkeleri, gizlilik-güvenlik prensipleri, servis kalitesi
        ve diğer uygulamaları sebebi ile oluşabilecek ihtilaf, maddi-manevi
        zarar ve kayıplardan YOUREPLY sorumlu değildir.
      </p>

      <p>
        Sitemizde yer alan her türlü bilgi, içerik ile bunların düzenlenmesi ve
        kısmen/tamamen kullanımı konusunda tüm fikri-sınai haklar ve mülkiyet
        hakları Youreply Teknoloji Ticaret Eğitim Danışmanlık Araştırma
        Organizasyon Anonim Şirketi’ne aittir. Sitemizde veya Sitemizi oluşturan
        tüm yazılımlara ilişkin kaynak kodu, veri kodu, yazılım ve sistem
        içerisindeki veri tabanı ve bunların geliştirilmiş versiyonları
        üzerindeki 5846 sayılı Fikir ve Sanat Eserleri Kanunu’nun 21. maddesinde
        “İşleme”, 22. maddesinde “Çoğaltma”, 23. Maddesinde “Yayma”, 24.
        maddesinde “Temsil” ve 25. maddesinde “İşaret Ses ve/veya Görüntü
        Nakline Yarayan Araçlarla Umuma İletim” hakları adı altında düzenlenen
        mali hakların tamamı üzerinde ve aynı kanunun 14. maddesinde “Umuma Arz
        Salahiyeti”, 15. maddesinde “Adın Belirtilmesi Salahiyeti” ve 16.
        maddesinde “Eserde Değişiklik Yapılmasını Men Etme” adı altında
        düzenlenen manevi hakları kullanma yetkisi dahil olmak ancak bunlarla
        sınırlı olmamak üzere tüm fikri mülkiyet hakları (telif hakkı ve sair
        hak) yer, süre, içerik, mecra ve sayı sınırı olmaksızın, münhasıran
        YOUREPLY’a aittir ve YOUREPLY‘ın yazılı açık onayı olmadan kopyalanamaz,
        tamamı ya da bir kısmı üzerinde işleme veya tersine mühendislik
        yapılamaz; asıl ve/veya işlenecek halinin/ tamamını veya bir kısmını
        doğrudan veya dolaylı olarak çoğaltılamaz; herhangi bir şekil veya
        yöntemle yayılamaz; doğrudan doğruya veya işaret, ses veya resim nakline
        yarayan araçlarla umumî mahallerde temsil suretiyle faydalanılamaz;
        dijital ortamlarda ve/ veya mobil araçlarla kullanılamaz, umuma
        iletilemez ve üçüncü kişilerin erişimine sunulamaz; Sözleşme tarihinde
        bilinen ve Sözleşme tarihinden sonra geliştirilecek teknik olanaklarla
        üretilecek veri taşımaya yarayan araçlara kaydedilemez; her türlü
        görsel-işitsel eser içerisinde kullanılamaz ve Sözleşme tarihinde
        bilinen veya ileride geliştirilecek yöntemlerle, işaret-ses ve/veya
        görüntü nakline yarayan araçlarla veya yeniden yayınlanmak suretiyle
        umuma iletilemez, sergilenemez, değiştirilemez, uyarlanamaz ya da
        tercüme edilemez. Kullanıcı’nın işbu hükme aykırı davrandığının
        YOUREPLY‘ın takdirine bağlı olarak tespit edilmesi halinde, YOUREPLY,
        Kullanıcı’nın siteye erişimini derhal engelleyebilir. YOUREPLY fikri
        haklar bakımından her türlü maddi ve manevi zararların tüm faiz ve
        fer’ilerin tahsili de dâhil olmak üzere her türlü hukuki ve cezai yola
        müracaat etme hakkı saklıdır.
      </p>

      <p>
        Kullanıcılar, Sözleşme’ye aykırı davranmaları durumunda ve/veya
        YOUREPLY‘ın dilediği zaman tek taraflı olarak Kullanıcıların üyeliğine
        son verebileceğini, üyelik hesabını geçici/kalıcı olarak
        kapatabileceğini, böyle bir durumda YOUREPLY’dan hiçbir talepte
        bulunmayacaklarını kabul ederler.
      </p>

      <p>
        Ek bilgi almak istediğiniz konularda Sitemizde belirtilen iletişim
        yolları ile Youreply Teknoloji Ticaret Eğitim Danışmanlık Araştırma
        Organizasyon Anonim Şirketi’ne danışabilirsiniz.
      </p>

      <p>
        Kullanıcı, siteden faydalanmasını sağlayan tüm yazılımların genel
        itibarıyla hatasız olmadığını ve işbu Sözleşme’de YOUREPLY tarafından
        siteyi oluşturan yazılımlara dair hiçbir beyan ve tekeffül
        verilmediğini, buradaki içerikler ve/veya yazılımlar sebebiyle
        kendisinin ve/veya 3.tarafların herhangi bir zarara uğraması durumunda
        YOUREPLY’ın bundan dolayı herhangi bir sorumluluğunun olmadığını
        bildiğini kabul ve beyan eder.
      </p>

      <p>
        Kullanıcı, spam, virüs ve bunlarla sınırlı olmaksızın benzeri diğer kötü
        niyetli yazılımlar ile Sitemizin çalışmasını durdurucu veya engelleyici
        iş, işlem veya eylemlerde bulunmayacağını veya bu yönde iş, işlem veya
        eylemlerde bulunan üçüncü kişilerle herhangi bir ilişki içerisinde
        olmayacağını kabul, beyan ve taahhüt eder.
      </p>

      <p>
        Hizmetlere veri sağlamaya ve geliştirmeye yönelik durumlar veya
        içeriğinizin bu Şartları veya diğer geçerli herhangi bir yasayı ihlal
        ettiğini düşündüğümüz durumlar da dâhil olmak üzere herhangi bir zamanda
        ve herhangi bir sebeple içeriğinize erişebilir, içeriğinizi
        inceleyebilir, tarama yapabilir veya silebiliriz. Yine de Hizmetler
        aracılığıyla oluşturduğunuz, yüklediğiniz, yayımladığınız, gönderdiğiniz
        veya sakladığınız içerik ile ilgili yegane sorumluluk size aittir.
      </p>

      <p>
        Kullanıcı, yegâne takdir yetkisi YOUREPLY’da olmak üzere, Sitemize
        erişim engellenmesine ilişkin mahkeme kararı ve YOUREPLY’ın kontrolünde
        olmayan teknik aksaklıklar dahil olmak üzere mücbir sebepler durumunda
        veya benzer sebeplerle herhangi bir zamanda, YOUREPLY’ın zaman zaman tüm
        hizmetlere veya hizmetlerin bir kısmına ya da Sitemize erişimi tüm
        Kullanıcılar için kısıtlayabileceğini kabul eder.
      </p>

      <p>
        Sözleşme’nin uygulanmasından doğabilecek uyuşmazlıkların çözümünde Türk
        Hukuku uygulanacak olup; ANKARA Mahkemeleri ve İcra Müdürlükleri yetkili
        olacaktır. Taraflar Sözleşme’den doğabilecek ihtilaflarda, YOUREPLY’ın
        defter ve kayıtları ile bilgisayar kayıtlarının Hukuk Muhakemeleri
        Kanunu’nun 193. maddesi anlamında muteber, bağlayıcı, kesin delil teşkil
        edeceğini ve bu maddenin delil sözleşmesi niteliğinde olduğunu kabul,
        beyan ve taahhüt ederler. Bu Sözleşme’nin herhangi bir maddesinin
        geçersiz ya da uygulanamaz hale gelmesi, Sözleşme’nin diğer maddelerini
        geçersiz kılmayacağı gibi; YOUREPLY’ın Sözleşme’den kaynaklanan
        haklarını kısmen veya tamamen kullanmaması hiçbir suretle bu haklardan
        feragat ettiği anlamına gelmemektedir.
      </p>

      <p>
        Kullanıcı, Sitemizde bulunduğu&nbsp;süre boyunca çerezlerin veya site
        kullanım verilerini analiz etme amaçlı javascript kodlarının veya benzer
        iz sürme verilerinin&nbsp;Kullanıcı’nın bilgisayarına
        yerleştirilebileceğini kabul eder. Çerezler basit metin dosyalarından
        ibaret olup, kimlik ve sair özel bilgiler içermez, bu nevi kişisel bilgi
        içermemekle beraber, oturum bilgileri ve benzeri veriler saklanır
        ve&nbsp;Kullanıcı’yı tekrar tanımak için kullanılabilir.&nbsp;
      </p>

      <p>
        Bu Sözleşme, siteye yeni özellikler eklendikçe veya site üyeleri ile
        kullanıcılarından yeni öneriler geldikçe yeniden, önceden tarafınıza
        herhangi bir bildirimde bulunulmaksızın düzenlenebilir ve
        güncellenebilir. Bu nedenle, siteyi her kullanışınızda Kullanıcı
        Sözleşmesinin ve Gizlilik Politikasının yeniden gözden geçirilmesi
        önerilmektedir.
      </p>

      <p>
        Biz, bağlı kuruluşlarımız ve üçüncü taraf ortaklarımız, bize
        sağladığınız, bizim topladığımız veya sizin hakkınızda edindiğimiz
        bilgilere dayanarak kişiselleştirilmiş reklamlar dâhil olmak üzere
        Hizmetlere reklam yerleştirebilir. Bazen içeriğinizin yakınında,
        arasında, üstünde ya da içinde reklamlar görünebilir.
      </p>

      <p>
        Sitenin kullanımı ile ilgili şikâyette bulunacaksanız, şikâyetiniz
        ilgili karardan sonraki altı ay içinde gönderilmelidir.
      </p>

      <p>
        Sözleşmede yer verilen şartlara uymadığınız takdirde, tüm rahatsız edici
        içerikleri kaldırma, hesabınızı kapatma veya görünürlüğünü sınırlandırma
        ve veri saklama politikalarımıza uygun olarak hesabınızla ilgili
        verileri saklama ve (emniyet birimleri dahil) üçüncü tarafları
        bilgilendirme ve bu üçüncü taraflara hesabınızla ilgili bilgileri verme
        hakkımız saklıdır.
      </p>

      <p>
        Hizmetlerimizi durmaksızın iyileştiriyor ve her zaman yenilerini
        yaratıyoruz. Yani yeni özellikler, ürünler veya işlevler ekleyebilir ve
        Hizmetleri tamamen askıya alabilir veya durdurabiliriz. Bu işlemlerden
        herhangi birini herhangi bir zamanda, herhangi bir sebeple
        gerçekleştirebiliriz ve bunu yaptığımızda size önceden bildirimde
        bulunmayabiliriz.
      </p>

      <p>
        Kullanıcılar, istediği zaman ve herhangi bir nedenle Hizmetleri
        kullanmayı bırakabilir veya hesabınızı silebilir.
      </p>

      <p>
        HİZMETLER “OLDUĞU GİBİ” VE “KULLANILABİLİR OLDUĞU ŞEKLİYLE” VE YASANIN
        İZİN VERDİĞİ ÖLÇÜDE TİCARETE ELVERİŞLİLİK, BELİRLİ BİR AMACA UYGUNLUK,
        MÜLKİYET VE İHLALDEN BERİ OLMA İLE İLGİLİ ÖRTÜLÜ GARANTİLER DÂHİL OLMAK
        ANCAK BUNLARLA SINIRLI OLMAMAK ÜZERE AÇIK VEYA ÖRTÜLÜ HİÇBİR GARANTİ
        VERİLMEDEN SUNULMAKTADIR. AYRICA, HİZMETLERİN HER ZAMAN GÜVENLİ, HATASIZ
        VEYA GÜNCEL OLACAĞI, HİZMETLERİN HER ZAMAN GECİKME, AKSAMA VEYA KUSUR
        OLMADAN ÇALIŞACAĞI VEYA HİZMETLER ÜZERİNDE VEYA ARACILIĞIYLA EDİNDİĞİNİZ
        HER TÜRLÜ İÇERİK, KULLANICI İÇERİĞİ VEYA BİLGİNİN GÜNCEL VEYA DOĞRU
        OLACAĞI KONUSUNDA BEYANDA BULUNMUYOR VEYA GARANTİ VERMİYORUZ.
      </p>

      <p>
        NE BİZ NE DE BAĞLI KURULUŞLARIMIZ, SİZİN, BAŞKA BİR KULLANICININ VEYA
        BİR ÜÇÜNCÜ TARAFIN HİZMETLERİMİZ ÜZERİNDE VEYA ARACILIĞIYLA YARATTIĞI,
        YÜKLEDİĞİ, YAYIMLADIĞI, GÖNDERDİĞİ, ALDIĞI VEYA SAKLADIĞI HİÇBİR İÇERİK
        İÇİN SORUMLULUK ALMAKTA YA DA YÜKÜMLÜLÜK KABUL ETMEKTEYİZ. SALDIRGAN,
        YASA DIŞI, YANILTICI VEYA BAŞKA ŞEKİLDE UYGUNSUZ İÇERİKLERLE
        KARŞILAŞABİLECEĞİNİZİ VE BUNLARIN HİÇBİRİNDEN BİZ VEYA BAĞLI
        KURUMLARIMIZIN SORUMLU OLMAYACAĞINI ANLAMAKTA VE KABUL ETMEKTESİNİZ.
      </p>

      <p>
        İşbu Şartlardaki herhangi bir hükmün uygulanamaz addedilmesi durumunda,
        söz konusu hüküm işbu Şartlardan çıkarılacak ve bu durum, kalan
        hükümlerin geçerliğini ve uygulanabilirliğini etkilemeyecektir.
      </p>

      <p>
        Bu Şartlar altındaki haklarımızı aktarma ve söz konusu kurumun işbu
        Şartlara uyması koşuluyla, Hizmetlerimizi başka bir kurumu kullanarak
        sağlama hakkımızı saklı tutuyoruz. Bu Şartlar kapsamındaki hak veya
        yükümlülüklerinizi iznimiz olmadan devredemezsiniz. Açık bir şekilde
        size verilmiş olmayan tüm hakları saklı tutarız.
      </p>
    </>
  );
}

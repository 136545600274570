import React from 'react';
import { FooterToolbar, ProCard } from '@ant-design/pro-components';
import { Alert, Col, Form, Row } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import SkeletonInput from 'antd/es/skeleton/Input';
import './FormContactSkeleton.scss';

export function FormContactSkeleton() {
  return (
    <div className="form-contact-skeleton">
      <Alert
        showIcon
        description="İletişim bilgileriniz üçüncü şahıslar ile kesinlikle paylaşılmayacaktır. Yalnızca yardımımıza ihtiyaç duyduğunuzda sizinle doğrudan iletişime geçebilmemiz için kullanılacaktır. "
        style={{ marginBottom: 16 }}
      />

      <Form layout="vertical">
        <ProCard title="İletişim Bilgileri" style={{ marginBottom: 16 }}>
          <Row gutter={16}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item label="Email">
                <SkeletonInput active />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item label="Telefon Numarası">
                <SkeletonInput active />
              </Form.Item>
            </Col>
          </Row>
        </ProCard>

        <FooterToolbar>
          <SkeletonButton />
        </FooterToolbar>
      </Form>
    </div>
  );
}

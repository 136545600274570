import React from 'react';
import { Link } from 'react-router-dom';
import { PageContainer } from '@ant-design/pro-components';
import { Result } from 'antd';

export default function Error404() {
  return (
    <PageContainer title="404" subTitle="Aradığınız sayfa bulunamadı">
      <Result
        status="404"
        style={{
          height: '100%',
          background: '#fff',
        }}
        title="404"
        subTitle="Aradığınız sayfa bulunamadı."
        extra={
          <Link to="/" type="primary">
            Ana Sayfaya Dön
          </Link>
        }
      />
    </PageContainer>
  );
}

import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import { IError, INviValidateData, IStatus } from '../Type';

export const nviValidateTc = async (
  data: INviValidateData,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query nviValidateTc($data: NviValidateData) {
          nviValidateTc(data: $data) {
            status
            message
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        data,
      },
    });

    return {
      result: result.data.nviValidateTc,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import { IError, IStatus } from '../Type';
import { IProfile, IProfilePatchData } from '../Type';

export const loadProfile = async (): Promise<{
  result?: IProfile;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query profile {
          profile {
            nameFirst
            nameLast
            birthdate
            identity
            email
            phone
            location0Id
            location1Id
            location2Id
            location3Id
            address
            zipCode
            isValid
            isActive
            isOnBoarded
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.profile,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const profileSave = async (
  data: IProfilePatchData,
): Promise<{
  result?: IProfile;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation profileSave($data: ProfilePatchData!) {
          profileSave(data: $data) {
            nameFirst
            nameLast
            birthdate
            identity
            email
            phone
            location0Id
            location1Id
            location2Id
            location3Id
            address
            zipCode
          }
        }
      `,
      variables: {
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.profileSave,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const profileDelete = async (): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation profileDelete {
          profileDelete {
            status
            message
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.profileDelete,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const profileValidate = async (): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation profileValidate {
          profileValidate {
            status
            message
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.profileValidate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const profileOnBoardInitial = async (): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation profileOnBoardInitial {
          profileOnBoardInitial {
            status
            message
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.profileOnBoardInitial,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const profileOnBoardModule = async (
  module: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation profileOnBoardModule($module: String!) {
          profileOnBoardModule(module: $module) {
            status
          }
        }
      `,
      variables: {
        module,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.profileOnBoardModule,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
